var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { AllPicklistDisplaySettings, PicklistDisplaySetting, getDisplayProperty, getPicklistFieldExpression, } from '@samc/picklist-api';
import { isNullOrUndefined } from '@samc/react-ui-core';
import { getDisplayValue } from '../../atoms/controls/Picklist/PicklistFunctions';
export class PicklistManager {
    /**
     * Builds a PicklistManager instance around a picklist
     * @param picklist the picklist to build around
     * @param initialItems the items to store into the PicklistManager instance, does not pull from picklist param
     */
    constructor(params) {
        /**
         * Saved persistant request results
         */
        this._picklistItems = [];
        /**
         * The total number of items for this picklist on the DB
         */
        this._totalItemCount = -1;
        /**
         * Tracks picklist items by id (lowercase string) for splicing, using the same references
         * as in _picklistItems (and update in _picklistItems will reflect here)
         */
        this._picklistItemsById = {};
        this._resolvedItems = {};
        /**
         * Maps [ResolutionSetting] => [Lowercase String Value] => [Lowercase String Id]
         */
        this._resolutionMapper = new Map(AllPicklistDisplaySettings.map((v) => [v, {}]));
        this._resolveQueue = [];
        /**
         * Writes new (sequential) items into storage
         */
        this._saveItems = (offset, items) => {
            const newIndexedItems = items.map((item, i) => ({
                index: i + offset,
                item,
            }));
            this._picklistItemsById = Object.assign(Object.assign({}, this._picklistItemsById), newIndexedItems.reduce((all, cur) => {
                const id = String(cur.item.id).toLowerCase().trim();
                delete this._resolvedItems[id]; // clear from resolutions
                // save into resolution mappers
                this._resolutionMapper.forEach((currentCache, key) => {
                    const fieldName = getDisplayProperty(key);
                    const value = getDisplayValue(cur.item, fieldName, 'id');
                    currentCache[String(value).toLowerCase().trim()] = id;
                });
                return Object.assign(Object.assign({}, all), { [id]: cur });
            }, {}));
            this._picklistItems = this._picklistItems
                .slice(0, offset)
                .concat(newIndexedItems)
                .concat(this._picklistItems.slice(offset + newIndexedItems.length));
        };
        /**
         * Saves item(s) as resolved and returns the new total resolved items.
         * DOES NOT cache items sequentially, only by id provided they aren't
         * already cached sequentially.
         * @param items items to save in resolved item store
         * @returns the new total resolved items
         */
        this._cacheResolution = (items) => {
            /**
             * Lowercase string index
             */
            const indexable = items.reduce((all, cur) => {
                const index = String(cur.id).toLowerCase().trim();
                if (this._picklistItemsById[index])
                    return all; // don't double-cache
                // save into resolution mappers
                this._resolutionMapper.forEach((currentCache, key) => {
                    const fieldName = getDisplayProperty(key);
                    const value = getDisplayValue(cur, fieldName, 'id');
                    currentCache[String(value).toLowerCase().trim()] = index;
                });
                return Object.assign(Object.assign({}, all), { [String(cur.id).toLowerCase().trim()]: cur });
            }, {});
            this._resolvedItems = Object.assign(Object.assign({}, this._resolvedItems), indexable);
            return this._resolvedItems;
        };
        this._purgeResolveQueue = () => __awaiter(this, void 0, void 0, function* () {
            const queuedResolutions = this._resolveQueue;
            this._resolveQueue = [];
            const ids = [];
            const filters = [];
            queuedResolutions.forEach(({ value, settings }) => {
                const _settings = Array.isArray(settings) ? settings : [settings];
                _settings.forEach((setting) => {
                    if (setting === PicklistDisplaySetting.Id)
                        ids.push(value);
                    else {
                        filters.push(`${getPicklistFieldExpression(getDisplayProperty(setting))} = ${typeof value === 'string' ? `'${value}'` : value}`);
                    }
                });
            });
            try {
                const { items } = yield this.fetchItems(Object.assign({ ids }, (filters.length > 0 && { filters: [filters.join(' OR ')] })));
                this._cacheResolution(items);
                queuedResolutions.forEach((r) => {
                    const target = this.getCachedItem(r.value, r.settings);
                    if (target)
                        r.resolve(target);
                    else
                        r.reject(new Error('Not found'));
                });
            }
            catch (e) {
                queuedResolutions.forEach((r) => r.reject(e));
            }
        });
        /**
         * Gets the picklist backing the instance
         */
        this.getPicklist = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            // eslint-disable-next-line no-return-assign
            return ((_b = (_a = this._picklist) !== null && _a !== void 0 ? _a : this._picklistPromise) !== null && _b !== void 0 ? _b : (this._picklistPromise = this._picklistGetter().then((r) => {
                this._picklistPromise = undefined;
                this._picklist = r;
                return r;
            })));
        });
        this.fetchItems = (params) => __awaiter(this, void 0, void 0, function* () {
            var _c;
            const { filters, ids, offset, limit: limitParam, sortOverride, hardReload, childDomainFilters } = params;
            if (hardReload)
                yield this.reset(); // clear all cache
            const limit = limitParam !== null && limitParam !== void 0 ? limitParam : 100;
            // only persist if it is globally applicable
            const persist = (!ids || ids.length === 0) &&
                !sortOverride &&
                (isNullOrUndefined(filters) || filters.length === 0) &&
                (isNullOrUndefined(childDomainFilters) || childDomainFilters.length === 0);
            if (persist) {
                yield ((_c = this._currentItemsPromise) === null || _c === void 0 ? void 0 : _c.catch());
                // check to see if already have
                const end = limit && offset ? offset + limit : undefined;
                const targetItems = this._picklistItems.slice(offset, end);
                if (this._totalItemCount >= 0) {
                    const expectedCount = Math.min(limit !== null && limit !== void 0 ? limit : Infinity, this._totalItemCount - (offset || 0));
                    const missingItemCount = Math.max(expectedCount - targetItems.length, 0);
                    if (missingItemCount <= 0) {
                        return {
                            items: targetItems.map((i) => i.item),
                            totalCount: this._totalItemCount,
                        };
                    }
                }
                const newOffset = (offset || 0) + targetItems.length;
                const newLimit = limit - targetItems.length;
                const promise = (() => __awaiter(this, void 0, void 0, function* () {
                    const picklist = yield this.getPicklist();
                    const { totalCount, items } = yield this._itemGetter(picklist, Object.assign(Object.assign({}, params), { filters: undefined, ids: undefined, limit: newLimit, offset: newOffset }));
                    this._totalItemCount = totalCount;
                    this._saveItems(newOffset, items);
                    return {
                        items,
                        totalCount,
                    };
                }))();
                this._currentItemsPromise = promise.then(() => {
                    this._currentItemsPromise = undefined;
                });
                return promise;
            }
            // check cache for ids
            const cachedItems = [];
            if (ids) {
                const missingIds = new Set(ids);
                ids.forEach((id) => {
                    const cached = this.getCachedItem(id);
                    if (cached) {
                        cachedItems.push(cached);
                        missingIds.delete(id);
                    }
                });
                // filters are ORed, so we can't skip if there is one
                if (missingIds.size === 0 && (!filters || filters.length === 0))
                    return {
                        items: cachedItems,
                        totalCount: cachedItems.length,
                    };
                params.ids = Array.from(missingIds);
            }
            const picklist = yield this.getPicklist();
            return this._itemGetter(picklist, params).then((output) => {
                this._cacheResolution(output.items);
                return {
                    items: cachedItems.concat(output.items),
                    totalCount: output.totalCount + cachedItems.length,
                };
            });
        });
        /**
         * Resolves a specific picklist id, batching with other calls
         * @param value the value to resolve
         * @param settings the setting used for resolving the value
         */
        this.resolveItem = (value_1, ...args_1) => __awaiter(this, [value_1, ...args_1], void 0, function* (value, settings = PicklistDisplaySetting.Id) {
            const target = this.getCachedItem(value, settings);
            if (target)
                return target;
            if (this._purgeResolveQueueTimeout) {
                clearTimeout(this._purgeResolveQueueTimeout);
                this._purgeResolveQueueTimeout = undefined;
            }
            return new Promise((resolve, reject) => {
                this._resolveQueue.push({
                    reject,
                    resolve,
                    settings,
                    value,
                });
                this._purgeResolveQueueTimeout = setTimeout(() => this._purgeResolveQueue(), 1000);
            });
        });
        /**
         * Resolves a set of picklist ids, batching them with other calls
         * @param values the values to resolve
         * @param settings the setting used for resolving the value
         * @returns the picklist items returned, not necessarily in order or in full (if some not resolvable)
         */
        this.resolveItems = (values_1, ...args_2) => __awaiter(this, [values_1, ...args_2], void 0, function* (values, settings = PicklistDisplaySetting.Id) {
            var _d;
            yield ((_d = this._currentItemsPromise) === null || _d === void 0 ? void 0 : _d.catch());
            const settled = yield Promise.allSettled(values.map((value) => this.resolveItem(value, settings)));
            return settled
                .map((s) => (s.status === 'rejected' ? undefined : s.value))
                .filter((v) => !!v);
        });
        /**
         * Removes all items cached on the picklist,
         * waiting for any requests to finish.
         */
        this.reset = () => __awaiter(this, void 0, void 0, function* () {
            yield this._currentItemsPromise;
            yield this._picklistPromise;
            this._picklist = undefined;
            this._picklistItems = [];
            this._picklistItemsById = {};
            this._resolvedItems = {};
            this._resolveQueue = [];
            this._totalItemCount = -1;
        });
        /**
         * Does not pull in resolved items, because they do not fall sequentially
         */
        this.getCachedItems = () => this._picklistItems;
        /**
         * Tries to fetch items in the cache
         * @param value the value (default: id) to fetch
         * @param settings the property/properties that we are searching
         * @returns the cached item, should it exist
         */
        this.getCachedItem = (value, settings = PicklistDisplaySetting.Id) => {
            var _a, _b, _c, _d, _e;
            const needle = String(value).toLowerCase().trim();
            let target;
            const _settings = Array.isArray(settings) ? settings : [settings];
            let i = 0;
            while (!target && i < _settings.length) {
                const setting = _settings[i];
                if (setting === PicklistDisplaySetting.Id) {
                    target = (_b = (_a = this._picklistItemsById[needle]) === null || _a === void 0 ? void 0 : _a.item) !== null && _b !== void 0 ? _b : this._resolvedItems[needle];
                }
                else {
                    const idNeedle = (_c = this._resolutionMapper.get(setting)) === null || _c === void 0 ? void 0 : _c[needle];
                    if (idNeedle)
                        target = (_e = (_d = this._picklistItemsById[idNeedle]) === null || _d === void 0 ? void 0 : _d.item) !== null && _e !== void 0 ? _e : this._resolvedItems[idNeedle];
                }
                i++;
            }
            return target;
        };
        const { itemGetter, picklistGetter } = params;
        this._itemGetter = itemGetter;
        this._picklistGetter = picklistGetter;
    }
}
export default PicklistManager;
