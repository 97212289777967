var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { mergeHeaders } from '@samc/react-ui-request';
import { GetValueFromData, SetValueFromData } from '@samc/react-ui-core';
import { GetHeaders } from './GetHeaders';
export const getPascalCaseFieldName = (fieldName) => `${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`;
export const getPascalCaseObject = (obj) => {
    const returnObj = {};
    Object.keys(obj).forEach((key) => {
        returnObj[getPascalCaseFieldName(key)] = obj[key];
    });
    return returnObj;
};
export const extractHeaders = (requestInit) => requestInit === null || requestInit === void 0 ? void 0 : requestInit.headers;
const fetchInternal = (input, init) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(input, init);
    if (response.status >= 400 && response.status < 600) {
        const body = yield response.json();
        if (body.developerMessage) {
            throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
        }
        if (body.messages && body.messages.length > 0) {
            throw new Error(`${response.statusText}: ${body.messages[0]}`);
        }
    }
    return response;
});
export const executeQuery = (url) => (params, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = mergeHeaders(GetHeaders(), extractHeaders(requestInit), { 'Content-Type': 'application/json' });
    const response = yield fetchInternal(`${url}/api/v2/Query/Execute?includeLimitedAccessRecords=true`, {
        method: 'POST',
        headers,
        body: JSON.stringify(params),
    });
    return response.json();
});
export const getPicklists = (url, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/picklist`, {
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return response.json();
});
export const getPicklist = (url, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/picklist/${id}`, {
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return response.json();
});
export const savePicklist = (url, requestInit) => (picklist) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/picklist`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit), { 'Content-Type': 'application/json' }),
        body: JSON.stringify(picklist),
    });
    return response.json();
});
export const deletePicklist = (url, requestInit) => (id) => __awaiter(void 0, void 0, void 0, function* () {
    yield fetchInternal(`${url}/api/v1/picklist/${id}`, {
        method: 'DELETE',
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return id;
});
export const PICKLIST_ITEM_SORT_ORDER_EXPRESSION = '[SortOrder]';
const PicklistItemAdhocListviewMembers = [
    {
        scalarExpression: '[Id]',
        scalarDisplayName: 'id',
    },
    {
        scalarExpression: '[ParentId]',
        scalarDisplayName: 'parentId',
    },
    {
        scalarExpression: '[LongName]',
        scalarDisplayName: 'longName',
    },
    {
        scalarExpression: '[ShortName]',
        scalarDisplayName: 'shortName',
    },
    {
        scalarExpression: '[DisplayExpression]',
        scalarDisplayName: 'displayExpression',
    },
    {
        scalarExpression: '[CustomShortName]',
        scalarDisplayName: 'customShortName',
    },
    {
        scalarExpression: '[CustomLongName]',
        scalarDisplayName: 'customLongName',
    },
    {
        scalarExpression: PICKLIST_ITEM_SORT_ORDER_EXPRESSION,
        scalarDisplayName: 'sortOrder',
    },
    {
        scalarExpression: '[IsActive]',
        scalarDisplayName: 'isActive',
    },
    {
        scalarExpression: '[IsSystemDefined]',
        scalarDisplayName: 'isSystemDefined',
    },
];
export const getPicklistItems = (url_1, domainId_1, requestInit_1, filter_1, sortingData_1, ...args_1) => __awaiter(void 0, [url_1, domainId_1, requestInit_1, filter_1, sortingData_1, ...args_1], void 0, function* (url, domainId, requestInit, filter, sortingData, offset = 0, limit = 1000, additionalFields = [], childDomainFilters = undefined) {
    const query = executeQuery(url);
    const adhocListViewMembers = [];
    const usedScalarDisplayNames = new Set();
    const caseDifferentials = [];
    // remove any duplicate scalar display names, preferring those passed as additioanl fields
    additionalFields.concat(PicklistItemAdhocListviewMembers).forEach((f) => {
        if (usedScalarDisplayNames.has(f.scalarDisplayName))
            return;
        if (usedScalarDisplayNames.has(getPascalCaseFieldName(f.scalarDisplayName))) {
            caseDifferentials.push(f.scalarDisplayName);
            return;
        }
        adhocListViewMembers.push(f);
        usedScalarDisplayNames.add(f.scalarDisplayName);
    });
    const hasChildFilters = childDomainFilters && childDomainFilters.length > 0;
    const hasStringFilter = !!filter;
    const fetch = (start = 0, stop = 2000) => query(Object.assign(Object.assign({ domainId,
        adhocListViewMembers }, ((hasStringFilter || hasChildFilters) && {
        adhocFilter: Object.assign(Object.assign({ expressionLang: 'Centric', filterName: 'Adhoc', advancedInd: true }, (hasStringFilter && { advancedExpression: filter })), (hasChildFilters && { childFilters: childDomainFilters })),
    })), { filterIds: [], sorting: sortingData || {
            order: 'asc',
            orderBy: {
                scalarExpression: PICKLIST_ITEM_SORT_ORDER_EXPRESSION,
            },
        }, paging: { start, stop }, summaryMode: false }), requestInit);
    const { Data, TotalRecords } = yield fetch(offset, offset + limit);
    const ouput = Data;
    if (caseDifferentials.length > 0) {
        // if LongName was requested, we need to map it into longName
        ouput.forEach((record) => caseDifferentials.forEach((cdif) => {
            SetValueFromData(record, cdif, GetValueFromData(record, getPascalCaseFieldName(cdif)));
        }));
    }
    return Object.assign(ouput, { totalCount: TotalRecords });
});
export const getPicklistItem = (url, domainId, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const query = executeQuery(url);
    const fetch = () => query({
        domainId,
        adhocListViewMembers: PicklistItemAdhocListviewMembers,
        adhocFilter: `[Id] = '${id}'`,
        filterIds: [],
        sorting: {},
        paging: { start: 0, stop: 1 },
        summaryMode: false,
    }, requestInit);
    const result = yield fetch();
    return result.Data.at(0);
});
export const submitTask = (url) => (request, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/task`, {
        method: 'POST',
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit), { 'Content-Type': 'application/json' }),
        body: JSON.stringify(request),
    });
    return response.json();
});
export const savePicklistItems = (taskUrl, domainId, fieldConfigurationId, requestInit) => (changedPicklistItems) => __awaiter(void 0, void 0, void 0, function* () {
    const changedItems = {};
    changedPicklistItems.forEach((r) => {
        changedItems[String(r.id)] = getPascalCaseObject(r); // pascal case for taskSubmit expressions
    });
    const request = {
        domainId,
        fieldConfigurationId,
        viewId: '',
        payload: changedItems,
    };
    return submitTask(taskUrl)(request, requestInit);
});
// screenconfig api calls
export const getFieldConfiguration = (url, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!id) {
        return Promise.reject(new Error('Invalid id'));
    }
    const response = yield fetchInternal(`${url}/api/v1/FieldConfiguration/${id}`, {
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return response.json();
});
export const getDomains = (url, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetchInternal(`${url}/api/v1/domain`, {
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return response.json();
});
export const getDomain = (url, id, requestInit) => __awaiter(void 0, void 0, void 0, function* () {
    if (!id) {
        return Promise.reject(new Error('Invalid id'));
    }
    const response = yield fetchInternal(`${url}/api/v1/domain/${id}`, {
        headers: mergeHeaders(GetHeaders(), extractHeaders(requestInit)),
    });
    return response.json();
});
