import * as React from 'react';
import { Grid, EditingProvider, useAgGridApi, ConvertGridFilterToFilterExpression, } from '@samc/react-ui-grid';
import { GlobalDirtinessTracker } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import { cloneData } from '@samc/react-ui-grid/lib/organisms/BaseGrid/BaseGridFunctions';
import { PicklistConfigFields } from '../../field-configs/collections/PicklistConfigFields/PicklistConfigFields';
import './PicklistResultGrid.css';
import { CreateItem, RemoveSortOrderFromInactive } from './PicklistResultGridFunctions';
import { getFilterModelForPicklist } from '../../../atoms/controls/Picklist/PicklistFunctions';
const noop = () => undefined;
const _PicklistResultGrid = (props, ref) => {
    var _a, _b, _c, _d, _e;
    const { fetchItems, onSubmit, domains, picklist, onChangeData, readonly } = props;
    const [api, onGridReady] = useAgGridApi();
    const { evaluate } = useEvaluator();
    const dirtinessScope = React.useRef(null);
    const currentDomain = domains.find((x) => x.id === (picklist === null || picklist === void 0 ? void 0 : picklist.domainId));
    const idField = (_a = currentDomain === null || currentDomain === void 0 ? void 0 : currentDomain.fields) === null || _a === void 0 ? void 0 : _a.find((x) => x.fieldName === 'Id');
    const isIdSettableOnAdd = !(picklist === null || picklist === void 0 ? void 0 : picklist.domainId) || ((_d = (_c = (_b = idField === null || idField === void 0 ? void 0 : idField.datasourceDataType) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === null || _c === void 0 ? void 0 : _c.indexOf('char')) !== null && _d !== void 0 ? _d : -1) >= 0;
    const isCustom = !!(picklist === null || picklist === void 0 ? void 0 : picklist.isUserDefined);
    const fields = React.useMemo(() => PicklistConfigFields(fetchItems, picklist, domains, isCustom, evaluate, readonly, isIdSettableOnAdd), [fetchItems, picklist, domains, isCustom, evaluate, readonly, isIdSettableOnAdd]);
    const handleChangeData = React.useCallback((args) => {
        if (onChangeData)
            onChangeData(args);
    }, [onChangeData]);
    const onGridStateChange = React.useCallback((params) => {
        if (!fields)
            return;
        const filters = [];
        let sortOverride;
        if (params.orderBy) {
            sortOverride = {
                order: params.order,
                orderBy: {
                    scalarExpression: `[${params.orderBy.charAt(0).toUpperCase() + params.orderBy.slice(1)}]`,
                },
            };
        }
        if (params.filterModel && Object.keys(params.filterModel).length) {
            const filterModel = getFilterModelForPicklist(params.filterModel, fields.wrappedFields);
            const filterExpression = ConvertGridFilterToFilterExpression(filterModel, fields.wrappedFields);
            if (filterExpression)
                filters.push(Object.assign(filterExpression.replace(/\[ParentId\]/gi, 'IIF(ISNULLOREMPTY([ParentId]), [Id], [ParentId])'), { idAllowed: true }));
        }
        fetchItems({
            filters,
            sortOverride,
            offset: params.startRow || 0,
            limit: params.rowsPerPage,
        }).then(({ items: rowData, totalCount: rowCount }) => {
            // Make sure the grid isn't referencing the original picklist item array
            const clonedRowData = cloneData(rowData);
            clonedRowData.forEach((d) => {
                var _a;
                // the grid expects the parent id to be provided, even if it matches root item id
                Object.assign(d, { parentId: (_a = d.parentId) !== null && _a !== void 0 ? _a : d.id });
            });
            params.success({
                rowCount,
                rowData: clonedRowData,
            });
        });
    }, [fetchItems, fields]);
    React.useImperativeHandle(ref, () => ({
        reload: () => GlobalDirtinessTracker.reset({ includeClean: true, element: dirtinessScope.current }),
    }), []);
    if (!fields)
        return null;
    return (React.createElement(EditingProvider, null,
        React.createElement("div", { ref: dirtinessScope, className: "picklist-result-grid-container" },
            React.createElement("div", { className: "picklist-result-grid" },
                React.createElement(Grid, { onSubmit: onSubmit !== null && onSubmit !== void 0 ? onSubmit : noop, fields: fields.wrappedFields, frameworkComponents: fields.frameworkComponents, onGridStateChange: onGridStateChange, isEditingDefault: !readonly, suppressEditToggle: readonly || ((_e = picklist === null || picklist === void 0 ? void 0 : picklist.isLocked) !== null && _e !== void 0 ? _e : false), suppressDeleteButton: true, onChangeData: handleChangeData, idField: "id", onBodyGridReady: onGridReady, suppressPinnedAddRow: true, newRowFactory: () => CreateItem(api), rowTransform: RemoveSortOrderFromInactive, rowsPerPage: 1000, stopEditingWhenCellsLoseFocus: true })))));
};
export const PicklistResultGrid = React.forwardRef(_PicklistResultGrid);
export default PicklistResultGrid;
