/**
 * Maps a {@link PicklistFieldChildDomainFilter} to a {@link ChildDomainFilter} for fetching data
 * @param input a {@link PicklistFieldChildDomainFilter} object
 * @returns a {@link ChildDomainFilter} object
 */
export const MapChildDomainFilter = (input) => {
    const { filterExpression, targetFieldExpression, foreignKeyFieldExpression, targetDomainId } = input;
    return {
        filterExpression,
        targetFieldScalarExpression: targetFieldExpression,
        foreignKeyFieldScalarExpression: foreignKeyFieldExpression,
        domainId: targetDomainId,
    };
};
export var PicklistDisplaySetting;
(function (PicklistDisplaySetting) {
    /**
     * Displays the custom short name where possible, falls back to record's ShortName.
     */
    PicklistDisplaySetting[PicklistDisplaySetting["CustomShortName"] = 0] = "CustomShortName";
    /**
     * Displays the custom long name where possible, falls back to record's LongName.
     */
    PicklistDisplaySetting[PicklistDisplaySetting["CustomLongName"] = 1] = "CustomLongName";
    PicklistDisplaySetting[PicklistDisplaySetting["CustomId"] = 2] = "CustomId";
    /**
     * Displays the short name where possible, falls back to record's CustomShortName.
     * NOTE: does not resolve parent short name on UI
     */
    PicklistDisplaySetting[PicklistDisplaySetting["ShortName"] = 3] = "ShortName";
    /**
     * Displays the long name where possible, falls back to record's CustomLongName.
     * NOTE: does not resolve parent long name on UI
     */
    PicklistDisplaySetting[PicklistDisplaySetting["LongName"] = 4] = "LongName";
    PicklistDisplaySetting[PicklistDisplaySetting["Id"] = 5] = "Id";
})(PicklistDisplaySetting || (PicklistDisplaySetting = {}));
export const AllPicklistDisplaySettings = [
    PicklistDisplaySetting.CustomId,
    PicklistDisplaySetting.CustomLongName,
    PicklistDisplaySetting.CustomShortName,
    PicklistDisplaySetting.Id,
    PicklistDisplaySetting.LongName,
    PicklistDisplaySetting.ShortName,
];
export var PicklistSortType;
(function (PicklistSortType) {
    PicklistSortType[PicklistSortType["Alphabetical"] = 0] = "Alphabetical";
    PicklistSortType[PicklistSortType["SortOrder"] = 1] = "SortOrder";
    PicklistSortType[PicklistSortType["CustomField"] = 2] = "CustomField";
})(PicklistSortType || (PicklistSortType = {}));
export var PicklistSetting;
(function (PicklistSetting) {
    PicklistSetting[PicklistSetting["Single"] = 0] = "Single";
    PicklistSetting[PicklistSetting["MultiSelect"] = 1] = "MultiSelect";
    PicklistSetting[PicklistSetting["GridSelectInput"] = 2] = "GridSelectInput";
    PicklistSetting[PicklistSetting["MultiGridSelectInput"] = 3] = "MultiGridSelectInput";
})(PicklistSetting || (PicklistSetting = {}));
