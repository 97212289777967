var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useRequestGetter } from '@samc/react-ui-request';
import { useEvaluator } from '@samc/expressions-react';
import { FunctionList, ValidateExpression } from '@samc/expressions-core';
import { getCombinedExpression } from '@samc/react-ui-core';
import { getPicklistItems } from '../api/Requests';
import { CacheTime } from '../CacheTime';
import { useDomainGetter } from '../useDomainGetter/useDomainGetter';
import { usePicklistGetter } from '../usePicklistGetter/usePicklistGetter';
import { useApiContext } from '../ApiContext';
import { getPicklistitemQueryFnDefaults, getUnifiedValidExpression, PICKLIST_FIELDS_KEY, } from '../usePicklistItems/usePicklistItems';
import { AllPicklistItemKeys } from '../model/PicklistItem';
export const convertToCamelCaseExpression = (expression) => expression.replace(/(?<=\[)[A-Z]/g, (v) => v.toLowerCase());
/**
 * Exposes a picklist item getter that fetches both
 * meta-driven and LS driven picklists. Applies filters to meta-driven picklist
 * items on the UI-side.
 */
export const usePicklistItemGetter = () => {
    const { LiftSiftApi, requestInit } = useApiContext();
    const query = ({ domainId, filter, sortingData, offset, limit, additionalFields, childFilters, }) => {
        return getPicklistItems(LiftSiftApi, domainId, requestInit, filter, sortingData, offset, limit, additionalFields, childFilters);
    };
    const picklistItemGetter = useRequestGetter({
        key: PICKLIST_FIELDS_KEY,
        query,
        options: { staleTime: CacheTime.picklistItemsLong },
    });
    const picklistGetter = usePicklistGetter();
    const domainGetter = useDomainGetter();
    const { evaluate } = useEvaluator();
    const totalRequest = React.useCallback((picklist, params) => __awaiter(void 0, void 0, void 0, function* () {
        const { filters, hardReload, limit: _limit, offset: _offset, sortOverride, ids, additionalFields, childDomainFilters, } = params;
        const offset = _offset !== null && _offset !== void 0 ? _offset : 0;
        const limit = _limit !== null && _limit !== void 0 ? _limit : 1000;
        const { domainId, picklistItems } = typeof picklist === 'string' ? yield picklistGetter(!!hardReload, picklist) : picklist;
        let otherFilters;
        if (filters) {
            if (domainId) {
                const domain = yield domainGetter(false, domainId);
                otherFilters = getUnifiedValidExpression(filters, domain); // this maps out id-based filters where applicable
            }
            else {
                otherFilters = getCombinedExpression(filters
                    .map((f) => Object.assign(convertToCamelCaseExpression(f), { idAllowed: f.idAllowed }))
                    .filter((f) => {
                    const { idAllowed } = f;
                    const validFields = AllPicklistItemKeys.filter((ff) => idAllowed || ff !== 'id');
                    return (ValidateExpression(f, FunctionList, validFields).errors.length === 0 // this maps out id-based filters
                    ); // remove invalid filters
                }));
            }
        }
        // sort items
        if (!domainId) {
            // case insensitive
            const idSet = new Set(ids === null || ids === void 0 ? void 0 : ids.map((id) => String(id).toLowerCase()));
            let output = (picklistItems || []).filter((pi) => {
                const results = [];
                if (idSet.size > 0)
                    results.push(idSet.has(String(pi.id).toLowerCase()));
                if (otherFilters)
                    results.push(evaluate(pi, otherFilters, true) === true);
                // ids and filters should be OR-ed together
                return results.length === 0 || results.some((r) => r);
            });
            const outputTotalCount = output.length;
            output = output.slice(offset);
            if (limit != null)
                output = output.slice(0, limit);
            if (sortOverride) {
                const { order, orderBy } = sortOverride;
                if (orderBy) {
                    const { scalarExpression } = orderBy;
                    output.sort((a, b) => {
                        const aResult = evaluate(a, convertToCamelCaseExpression(scalarExpression), true);
                        const bResult = evaluate(b, convertToCamelCaseExpression(scalarExpression), true);
                        const multiplier = order === 'asc' ? 1 : -1;
                        if (aResult > bResult)
                            return multiplier * 1;
                        // eslint-disable-next-line eqeqeq
                        if (aResult == bResult)
                            return 0;
                        return multiplier * -1;
                    });
                }
            }
            return { totalCount: outputTotalCount, items: output };
        }
        let filter;
        if (ids && ids.length > 0) {
            const existingItemIdSet = new Set(ids.filter((id) => (typeof id === 'number' ? id >= 0 : true) && id !== '' && id !== null && id !== undefined));
            // Yes, if [Id] is 1, it will still match against '1' (thanks L&S)
            filter = `LOWER([Id]) IN (${Array.from(existingItemIdSet)
                .map((id) => typeof id !== 'string' ? `'${id}'` : `'${id.replace("'", "'+ CHAR(39)+'").toLowerCase()}'`)
                .join(',')})`;
        }
        if (filter && otherFilters)
            filter = `(${filter}) OR (${otherFilters})`; // use OR for ids with filters
        else if (!filter && otherFilters)
            filter = otherFilters;
        else if (!filter)
            filter = 'true';
        const result = yield picklistItemGetter(!!hardReload, getPicklistitemQueryFnDefaults({
            domainId,
            filter,
            sortingData: sortOverride,
            offset,
            limit,
            additionalFields,
            childFilters: childDomainFilters,
        }));
        return { items: result, totalCount: result.totalCount };
    }), [domainGetter, evaluate, picklistGetter, picklistItemGetter]);
    return totalRequest;
};
export default usePicklistItemGetter;
