import * as React from 'react';
import { InputType, usePicklistGetter, usePicklistItemGetter, } from '@samc/picklist-api';
import { toastError, useRefWrapper } from '@samc/react-ui-core';
import { useEvaluator } from '@samc/expressions-react';
import { getPicklistCellEditor } from '../../grid/editors/PicklistCellEditor/PicklistCellEditor';
import { PicklistColumnFloatingFilter } from '../../grid/filters/PicklistColumnFilter/PicklistColumnFloatingFilter';
import { PicklistCustomTooltip, } from '../../grid/renderers/PicklistCellRenderer/PicklistCustomTooltip';
import { PicklistColumnFilter } from '../../grid/filters/PicklistColumnFilter/PicklistColumnFilter';
import { PicklistFieldValidator } from '../../grid/field-validators/PicklistFieldValidator/PicklistFieldValidator';
import { PicklistCellRenderer, } from '../../grid/renderers/PicklistCellRenderer/PicklistCellRenderer';
import { GridPicklistManager } from '../../utilities/GridPicklistManager/GridPicklistManager';
import { PartialExpressionEvaluator } from '../../utilities/PartialExpressionEvaluator/PartialExpressionEvaluator';
import { getPicklistFieldSort } from '../../atoms/controls/Picklist/PicklistFunctions';
import { getPicklistValueGetter } from '../../grid/value-getters/PicklistValueGetter/PicklistValueGetter';
import { getPicklistRowChangeHandler } from '../../grid/change-handlers/PicklistRowChangeHandler';
import { mapFlatParamsToUsePicklistGridFieldsParams, } from './Types';
import { getGridAdhocChildDomainFilter } from './Utilities';
// backwards compatibility
export * from './Types';
export const transformPicklistGridFieldConfigurations = (gridFields, getGridPicklist, evaluate, initialFrameworkComponents, useGridFieldConfigurations, customRenderers, evaluateAsync) => {
    const frameworkComponents = Object.assign(Object.assign({}, initialFrameworkComponents), { AgPicklistFloatingFilter: PicklistColumnFloatingFilter, AgPicklistTooltip: PicklistCustomTooltip, PicklistCellRenderer, AgPicklistFilter: PicklistColumnFilter });
    const partialEvaluator = new PartialExpressionEvaluator(evaluate);
    const gridFieldConfigs = gridFields.map((f) => {
        const { picklistField } = f;
        if (!picklistField)
            return f;
        try {
            const field = Object.assign({}, f);
            field.editor = getPicklistCellEditor(picklistField, getGridPicklist, useGridFieldConfigurations);
            field.type = 'string'; // left aligns
            if (typeof picklistField !== 'function' && field.filter !== false) {
                field.floatingFilterComponent = 'AgPicklistFloatingFilter';
                field.floatingFilterComponentParams = {
                    picklistField,
                    getPicklist: getGridPicklist,
                    useGridFieldConfigurations,
                };
                field.filter = 'AgPicklistFilter';
                field.filterParams = {
                    picklistField,
                    getPicklist: getGridPicklist,
                };
            }
            else {
                field.filter = false;
            }
            field.valueGetter = getPicklistValueGetter(picklistField, getGridPicklist);
            field.fieldValidator = PicklistFieldValidator(picklistField, getGridPicklist, partialEvaluator, f.field);
            field.suppressTypeThrough = true; // don't allow typing into field
            field.tooltipComponent = 'AgPicklistTooltip';
            field.tooltipComponentParams = {
                picklistField,
                getPicklist: getGridPicklist,
            };
            field.tooltipValueGetter = ({ value }) => value; // wipe away any existing ones
            field.cellRendererParams = {
                customRenderer: customRenderers === null || customRenderers === void 0 ? void 0 : customRenderers[field.field],
                getPicklist: getGridPicklist,
                picklistField,
            };
            field.cellRenderer = 'PicklistCellRenderer';
            field.onRowChanged = getPicklistRowChangeHandler({
                picklistField,
                getPicklist: getGridPicklist,
                fieldName: field.field,
                partializer: partialEvaluator,
                evaluate: evaluateAsync !== null && evaluateAsync !== void 0 ? evaluateAsync : evaluate,
            });
            const originalSuppressKeyboardEvent = field.suppressKeyboardEvent;
            // allow using the enter key in the editor
            field.suppressKeyboardEvent = (params) => {
                if (originalSuppressKeyboardEvent && originalSuppressKeyboardEvent(params))
                    return true;
                const { editing, event } = params;
                const { key } = event;
                if (editing && key === 'Enter')
                    return true;
                return false;
            };
            return field;
        }
        catch (e) {
            toastError(String(e));
            return f;
        }
    });
    return {
        wrappedFields: gridFieldConfigs,
        frameworkComponents,
    };
};
export function usePicklistGridFields(...params) {
    const { fieldConfigurationMembers, gridFields, filters, initialFrameworkComponents, gridSortingData, gridAdhocFilter, useGridFieldConfigurations, customRenderers, gridDomain, } = params.length === 1 ? params[0] : mapFlatParamsToUsePicklistGridFieldsParams(params);
    const getPicklistItems = usePicklistItemGetter();
    const getDBPicklist = usePicklistGetter();
    const asyncEvaluator = useEvaluator(undefined, true);
    const { evaluate } = useEvaluator();
    // indexed by picklist field id
    const picklists = React.useMemo(() => {
        return Object.values(fieldConfigurationMembers).reduce((all, cur) => {
            const { picklistField } = cur;
            if (!picklistField)
                return all;
            const picklistItemGetterFn = (picklist, p) => {
                var _a;
                const newParams = Object.assign({}, p);
                const defaultSort = getPicklistFieldSort(picklistField);
                if (!newParams.ids) {
                    newParams.sortOverride = (_a = newParams.sortOverride) !== null && _a !== void 0 ? _a : defaultSort;
                    const newFilters = [];
                    if (filters)
                        newFilters.push(...filters);
                    if (newParams.filters)
                        newFilters.push(...newParams.filters); // param takes precedence
                    else if (gridAdhocFilter)
                        newFilters.push(gridAdhocFilter);
                    newParams.filters = newFilters;
                }
                return getPicklistItems(picklist, newParams);
            };
            const gridPicklist = new GridPicklistManager({
                picklistGetter: () => getDBPicklist(true, picklistField.picklistId),
                itemGetter: picklistItemGetterFn,
            });
            /**
             * The gridPicklist instance to be used by the filter, specifically
             */
            const gridPicklistForFilter = !gridDomain
                ? gridPicklist
                : new GridPicklistManager({
                    picklistGetter: gridPicklist.getPicklist,
                    itemGetter: (picklist, p) => {
                        var _a;
                        const { domainId } = picklist;
                        if (!domainId)
                            return gridPicklist.fetchItems(p); // proxied
                        const newParams = Object.assign({}, p);
                        // this logic ensures that only items that are used in the grid are shown in the picklist
                        const adhocGridChildDomainFilter = getGridAdhocChildDomainFilter({
                            fieldConfigurationMember: Object.assign(Object.assign({}, cur), { picklistField }),
                            gridDomain,
                            gridFilters: filters !== null && filters !== void 0 ? filters : [],
                        });
                        newParams.childDomainFilters = [
                            ...((_a = newParams.childDomainFilters) !== null && _a !== void 0 ? _a : []),
                            adhocGridChildDomainFilter,
                        ];
                        return gridPicklist.fetchItems(newParams).catch((e) => {
                            // stop-gap measure to prevent errors from being thrown due to LS join issues
                            console.warn(`Error fetching picklist items for ${picklistField.id} for picklist grid filter, trying again without child domain filter`, e);
                            return gridPicklist.fetchItems(p);
                        });
                    },
                });
            return Object.assign(Object.assign({}, all), { [picklistField.id]: gridPicklist, [`${picklistField.id}_filter`]: gridPicklistForFilter });
        }, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldConfigurationMembers, filters, gridAdhocFilter, gridSortingData]);
    const picklistsRef = useRefWrapper(picklists);
    // this function will never re-calculate, safe to pass to grid as callbacks
    const getPicklist = React.useCallback((picklistField, location) => {
        switch (location) {
            case 'filter':
                return picklistsRef.current[`${picklistField.id}_filter`];
            default:
                return picklistsRef.current[picklistField.id];
        }
    }, [picklistsRef]);
    const gridFieldConfigsWithPicklistFields = React.useMemo(() => {
        const newGridFields = [];
        gridFields.forEach((gf) => {
            let picklistField;
            const fieldConfiguration = fieldConfigurationMembers[gf.field];
            if (fieldConfiguration) {
                const { inputType, picklistField: fetchedPicklistField } = fieldConfiguration;
                if (inputType === InputType.Picklist && fetchedPicklistField)
                    picklistField = fetchedPicklistField;
            }
            newGridFields.push(Object.assign(Object.assign({}, gf), { picklistField }));
        });
        return newGridFields;
    }, [gridFields, fieldConfigurationMembers]);
    const { wrappedFields, frameworkComponents } = React.useMemo(() => transformPicklistGridFieldConfigurations(gridFieldConfigsWithPicklistFields, getPicklist, evaluate, initialFrameworkComponents, useGridFieldConfigurations, customRenderers, asyncEvaluator.evaluate), [
        evaluate,
        getPicklist,
        gridFieldConfigsWithPicklistFields,
        initialFrameworkComponents,
        useGridFieldConfigurations,
        customRenderers,
        asyncEvaluator,
    ]);
    return { wrappedFields, frameworkComponents, picklists };
}
export default usePicklistGridFields;
